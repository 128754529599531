import {mapActions, mapGetters, mapMutations} from "vuex";
import dressCardTable from "./dress-card-table/index.vue";
//sections
export default {
  name: "dress-card",
  components: {
    dressCardTable,
  },
  props: {
    dress: {
      type: Object,
      default: ()=> {},
    }
  },
  data() {
    return {
      tableArray: []
    }
  },
  created() {
    this.tableArray.push(JSON.parse(JSON.stringify({...this.dress, uuid: Date.now()})))
  },
  watch: {

  },
  computed: {
    ...mapGetters({

    }),
  },
  mounted() {

  },
  methods: {
    handleAddDress() {
      const newDress = JSON.parse(JSON.stringify({...this.dress, uuid: Date.now()})); // Глубокая копия
      this.tableArray.push(newDress);
    },
    handleDeleteDress(index) {
      this.tableArray = this.tableArray.filter(el => el.uuid !== index)
    }
  }
}