import {mapActions, mapGetters, mapMutations} from "vuex";

import mainSelect from '@/components/main-select/index.vue';
import dressCard from "./components/dress-card/index.vue";
//sections
export default {
  name: "collection",
  components: {
    mainSelect,
    dressCard,
  },
  data() {
    return {
      filterOptions: [],
      queryPayload: {
        filter: null,
        query: '',
      },
      dressCollection: []
    }
  },
  created() {

  },
  watch: {
    '$route.params.slug': {
        immediate: true,
        handler(newVal){
          if(newVal){
            this.fetchCollectionData(newVal).then(()=>{
              this.fetchDressList(newVal).then(()=>{
                this.queryPayload.query = ''
                this.dressCollection = this.dressList.map(el => {
                  return {...el, filtered: true}
                })
                this.filterOptions = this.dressList.map(el => {
                  return {
                    title: el.title,
                    value: el.title
                  }
                })
                this.filterOptions = [{title: 'All dresses', value: 'all'}, ...this.filterOptions]
                this.queryPayload.filter = this.filterOptions[0]
              })
            })
          }
        }
    },
    queryPayload: {
      deep: true,
      handler(newVal){
        if(newVal){
          this.dressCollection.forEach(el => {
            if(this.queryPayload.filter.value !== 'all') {
              el.filtered = el.title.toUpperCase() === this.queryPayload.filter.value.toUpperCase()
            } else {
              el.filtered = true
            }
          })
          this.dressCollection.forEach(el => {
            if(el.filtered && this.queryPayload.query.length) {
              el.filtered = el.title.toUpperCase().includes(this.queryPayload.query.toUpperCase())
            }
          })
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      dressList: 'collections/collectionDressList',
      dressListLoading: 'collections/collectionDressListLoading',
      collectionData: 'collections/collectionData',
      collectionDataLoading: 'collections/collectionDataLoading'
    }),
    showEmpty(){
      return !this.dressCollection.filter(el => el.filtered).length
    }

  },
  mounted() {

  },
  methods: {
    ...mapActions({
      fetchCollectionData: 'collections/GET_COLLECTION_DATA',
      fetchDressList: 'collections/GET_COLLECTION_DRESS_LIST'
    })
  }
}